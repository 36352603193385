<template>
    <div class="brand-posts">
        <div class="head-info">

            <div class="title">
                <h2>
                    <span>Content (beta)</span>
                    <p class="small-text">Insights available 7 days after posting. Data updated daily.<span class="link"
                            v-if="otherTag.hastags.length > 0 || otherTag.instagram_mentions.length > 0 || otherTag.tiktok_mentions.length > 0" @click="goSetting()">
                            Update content settings.</span></p>
                </h2>
                <div class="update-time" v-if="!stat_loading">
                    <time>Updated: {{ daysAgo(updated_time) }}</time>
                    <DatePicker class="btn" @update-date="updateDate"
                        :date_range="{ start_date: start_date, end_date: end_date }"
                        :is_post_pick="$store.state.user_data.contract_status ? true : false"
                        :maxDate="$store.state.user_data.contract_status ? $store.state.user_data.contract_end_time : null"
                        :minDate="$store.state.user_data.contract_status ? handle_contract_start : null" />
                </div>

                <Button class="import-btn" size="32" color="dark-blue" :border_r="'6'"
                    @click="is_show_import_modal = true" :is_disabled="!had_m_ht || creator_no_connect"><svg-icon
                        name="plus" /> Import</Button>
            </div>
            <div class="select-tags" :class="{ 'is-loading': is_loading_tags }" v-if="had_m_ht">
                <!-- <h3>Select Tags:</h3> -->
                <div class="tags" v-if="tags.length > 0">
                    <Checkbox size="small" :key="'all'" :label_text="'All'" :checked="selected_tags.length === 0"
                        @click="selectAll" />
                    <Checkbox size="small" v-for="(v, k) in tags" :key="k" :label_text="v.text"
                        :checked="selected_tags.filter((t) => t.id === v.id).length !== 0" @click="selectTag(v)" />

                </div>
                <Button class="js-open-tags new-btn" color="dark-blue" size="24" v-if="tags.length > 0"
                    @click="openTags(false, $event)"><svg-icon name="plus" /></Button>
                <i class="open-popup js-open-tags" @click="openTags(true, $event)" v-if="tags.length > 0"><svg-icon
                        name="edit" /></i>

                <DropdownHashtag 
                    :tags="tags" :otherTag="otherTag" :selected_tags="selected_tags"
                    @select-all="selectAll" @select-tag="selectTag" ref="pop"
                    :drop_css="drop_css" />

            </div>
        </div>

        <div class="content-box">
            <ContentSummary :chart_head="chart_head1" :is_empty="!had_m_ht"
                v-if="$store.state.creator_data.tiktok_connect_status && tiktok_posts_count">
                <template #head>
                    <header>
                        <h3><svg-icon name="tiktok-pure" />{{ data_info.tiktok_handle }}</h3>
                        <p><strong>Posts ({{ tiktok_posts_count }} total)</strong></p>
                    </header>
                </template>
            </ContentSummary>
            <ContentSummary :chart_head="chart_head2" :is_empty="!had_m_ht"
                v-if="$store.state.creator_data.instagram_connect_status && instagram_posts_count">
                <template #head>
                    <header>
                        <h3><svg-icon name="instagram" />@{{ data_info.instagram_handle }}</h3>
                        <p><strong>In-Feed ({{ instagram_posts_count }} total)</strong></p>
                    </header>
                </template>
            </ContentSummary>
            <ContentSummary :chart_head="chart_head3" :is_empty="!had_m_ht"
                v-if="$store.state.creator_data.instagram_connect_status && story_count">
                <template #head>
                    <header>
                        <h3 v-if="!instagram_posts_count"><svg-icon name="instagram" />@{{ data_info.instagram_handle }}</h3>
                        <p><strong>Story ({{ story_count }} total)</strong></p>
                        <p><span>Story media metrics are only available for 24 hours. If you add a hashtag or mention to
                                follow after a story has run, the metrics will not be available.</span></p>
                    </header>
                </template>
            </ContentSummary>
            <TopBrandPost v-if="!top_post.loading" title="Branded Posts" :is_brand="true" :had_tag="false" :posts="top_post.data"
                :posts_value="top_post" @updateSort="updateSort" @selectTop="selectTopNavi" @callApiAgain="callApiAgain"
                :had_navi="true" :now_tab="top_post.top_menu_index === 0 ? 1 : 0" @changeIgTag="changeIgTag"
                @creator_no_connect-fun="creatorFun" :is_click_by_user="is_click_by_user" @findStop="is_click_by_user=true">

            </TopBrandPost>
        </div>


    </div>
    <Modal :is_show="is_show_modal" @click-bg="is_show_modal = false" class="hashtag-modal">
        <header class="tag-header">Update Tags <i @click="is_show_modal = false"><svg-icon name="close" /></i></header>
        <Hashtag :only_tag="true" />
    </Modal>

    <Modal :is_show="is_show_import_modal" @click-bg="is_show_import_modal = false" class="hashtag-modal import-modal">
        <header class="tag-header">Import Social Post<i @click="is_show_import_modal = false"><svg-icon
                    name="close" /></i>
        </header>
        <!-- <ul class="tag-menus">
            <li>
                <Button v-if="$store.state.creator_data.tiktok_handle" :class="{ 'disabled': soc_tab !== 1 }" @click="soc_tab = 1" :style="'border-square'" size="32"
                    color="dark-blue">TikTok</Button>
            </li>
            <li>
                <Button v-if="$store.state.creator_data.instagram_handle" :class="{ 'disabled': soc_tab !== 2 }" @click="soc_tab = 2" :style="'border-square'" size="32"
                    color="dark-blue">Instagram</Button>
            </li>

        </ul> -->
        <ImportUrl :which_handle="soc_tab === 1 ? 'TikTok' : 'Instagram'" :anchor_creator_id="data_info.id"
            :type="soc_tab" @refrash="importAndRefrash" />
    </Modal>
</template>

<script>
import CreatorSmallCard from '@/components/CreatorSmallCard.vue';
import Button from '@/components/ui/Button.vue';
import NoData from './creator/NoData.vue';
// import StorefrontSummary from '@/components/summary/StorefrontSummary.vue';
import ContentSummary from '@/components/summary/ContentSummary.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import TopBrandPost from '@/components/TopBrandPost.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import Modal from './ui/Modal.vue';
import Hashtag from './Hashtag.vue';
import ImportUrl from './ImportUrl.vue';
import { HandleNum, HandleNumSpec } from '@/utils/SwitchFun';
import { SwitchTopSort, get_all_post_data } from '@/utils/ContentSwitchFun.js';
import formatDateDaysAgo from '@/utils/formatDateDaysAgo';
import DropdownHashtag from '@/components/DropdownHashtag.vue';
import moment from 'moment';

export default {
    name: 'brand-posts',

    data() {
        return {
            el: null,
            now_tab: 0,
            soc_tab: this.$store.state.creator_data.tiktok_handle ? 1 : 2,
            sticker_tab: [
                { name: 'All Platform', index: 0 },
                { name: 'TikTok', index: 1, svg: 'tiktok-pure' },
                { name: 'Instagram', index: 2, svg: 'instagram' },
            ],
            is_show_modal: false,
            is_show_import_modal: false,
            is_load_nextpage: false,
            is_end: false,
            drop_css: '',
            posts: [],
            tags: [],
            otherTag: {
                hastags: [],
                instagram_mentions:[],
                tiktok_mentions:[],
            },
            selected_tags: [],
            is_loading_tags: true,
            top_post: JSON.parse(JSON.stringify(get_all_post_data)),
            start_date: moment().add(-30, 'days').toDate(),
            end_date: moment().add(-1, 'days').toDate(),
            stat_loading: false,
            updated_time: '',
            chart_head: [
                { name: 'Views', value: '---', field: 'total_view', trend: 'page_views_trend', show: true, content: `Total views for the creator’s matched posts. <br><br>TikTok views are counted when a video plays, including autoplays and loops. <br><br>Instagram views are counted when a video is watched for 3+ seconds and does not include video loops.` },
                { name: 'TikTok Engagement', value: '---', field: 'tiktok_total_engagement', trend: 'page_views_trend', show: true, content: `Number of likes, comments, saves, and shares, compared to overall reach, for the creator's matched posts.` },
                { name: 'Instagram Engagement', value: '---', field: 'instagram_total_engagement', trend: 'page_views_trend', show: true, content: `Total number of reactions, comments, and saves of a matched post / number of followers. This counts all reactions and comments, including ones that were removed or deleted.` },
                { name: 'Posts', value: '---', field: 'total_posts', trend: 'page_views_trend', show: true, content: `Total number of matched posts by this creator.` },
            ],
            chart_head1: [],
            chart_head2: [],
            chart_head3: [],
            tiktok_posts_count: 0,
            instagram_posts_count: 0,
            story_count: 0,
            creator_no_connect: false,
            had_m_ht: true,
            is_click_by_user:false,
        };
    },

    components: {
        CreatorSmallCard,
        NoData,
        Button,
        ContentSummary,
        TopBrandPost,
        DatePicker,
        Checkbox,
        Modal,
        Hashtag,
        ImportUrl,
        DropdownHashtag,
    },

    computed: {
        socisl_post_type() {
            //video reel post story
            if (this.top_post.top_menu_index === 1) {
                if (this.top_post.ig_post_type === 0) {
                    return 'post'
                } else if (this.top_post.ig_post_type === 1) {
                    return 'reel'
                } else {
                    return 'story'
                }
            } else {
                return 'video'
            }
        },
        handle_date_range() {
            return {
                start_date: this.$store.state.user_data.contract_status ? moment(this.$store.state.user_data.contract_start_time).format('YYYY-MM-DD') : this.date_range.start_date,
                end_date: this.$store.state.user_data.contract_status ? moment().add(-1, 'days').format('YYYY-MM-DD') : this.date_range.end_date,
            }
        },
        handle_contract_start() {
            return this.$store.state.user_data.contract_start_time ? moment(this.$store.state.user_data.contract_start_time).format('YYYY-MM-DD') : this.date_range.start_date;
        }
    },

    props: {
        user_id: {
            type: Number,
            default: 0
        },
        data_info: {
            type: Object,
            default: null,
        },
        date_range: {
            type: Object,
            default: null,
        },
    },

    methods: {
        openTags(is_drop, e) {
            this.drop_css = 'left: ' + e.target.offsetLeft + 'px; transform: translateX(-50%);';
            this.$refs.pop.open(is_drop);
        },

        handle_post_type(){
            //video reel post story
            if (this.top_post.top_menu_index === 1) {
                if (this.top_post.ig_post_type === 0) {
                    return 'post'
                } else if (this.top_post.ig_post_type === 1) {
                    return 'reel'
                } else {
                    return 'story'
                }
            } else {
                return 'video'
            }
        },

        daysAgo(date) {
            return formatDateDaysAgo(date);
        },

        creatorFun(val) {
            this.creator_no_connect = val;
        },

        changeIgTag(index,is_click_by_user) {
            this.is_click_by_user=is_click_by_user;
            this.top_post.ig_post_type = index;
            this.getPosts();
        },

        clickTab(index) {
            this.now_tab = index;
            this.handleChartHead();

            switch (index) {
                case 1:
                    this.top_post.top_menu_index = 0;
                    break;
                case 2:
                    this.top_post.top_menu_index = 1;
                    break;
            }

            // this.top_post.top_menu_index = index === 1 ? 0 : index === 2 ? 1 : this.top_post.top_menu_index;
            this.getStatistic();
            this.getPosts();
        },

        selectAll() {
            this.selected_tags = [];
            this.getStatistic();
            this.getPosts();
        },

        selectTag(tag) {
            const index = this.selected_tags.findIndex((t) => t.id === tag.id);
            if (index === -1) {
                this.selected_tags.push(tag);
            } else {
                this.selected_tags.splice(index, 1);
            }

            this.getStatistic();
            this.getPosts();
        },

        getTags() {
            if (this.$store.state.user_data) {
                this.$ajax(`creator/get-social-post-filter`, {
                    method: 'get',
                }).then(res => {
                    this.is_loading = false;
                    const data = res.data.data;
                    if (!data) return;

                    this.tags = data.social_post_tag_texts;
                    this.otherTag.hastags = data.social_post_hashtag_texts;
                    this.otherTag.instagram_mentions= data.social_post_instagram_mention_texts;
                    this.otherTag.tiktok_mentions=data.social_post_tiktok_mention_texts;
                    this.top_post.tag_select = this.tags;

                    this.had_m_ht = (data.social_post_hashtag_texts.length === 0 && data.social_post_mention_texts.length === 0) ? false : true;
                    this.is_loading_tags = false;

                }).catch(err => {
                    console.log(err)
                })
            }
        },

        selectTopNavi(index , is_click_by_user) {
            this.top_post.top_menu_index = index;
            this.is_click_by_user=is_click_by_user;
            this.getPosts();
        },

        updateSort(id) {
            let getVal = SwitchTopSort(id.id);
            this.top_post.sort.sort_key = getVal.key;
            this.top_post.sort.sort_val = getVal.val;
            this.top_post.sort_index = this.top_post.sort_select.findIndex(obj => obj.id === id.id);

            this.getPosts();
        },

        getStatistic() {
            this.stat_loading = true;
            let sp = '';

            switch (this.now_tab) {
                case 1:
                    sp = 'tikTok';
                    break;
                case 2:
                    sp = 'instagram';
                    break;
            }

            const tag_ids = [];
            this.selected_tags.forEach((tag) => {
                tag_ids.push(tag.id);
            });

            const params_data = {
                anchor_creator_id: this.user_id,
                merchant_account_id: this.$store.state.user_data.merchant_account_id,
                start_date: this.start_date,
                end_date: this.end_date,
                social_platform: sp,
                tag_ids: tag_ids,
            }

            this.$ajax('/creator/get-social-account-brand-content-posts-statistic', {
                method: 'get',
                params: params_data,
                withCredentials: true,
            }).then(res => {
                let vals = res.data.data.social_platform_stats;
                this.updated_time = vals.last_update_time;

                const pushValue = (val, tk) => {
                    let arr = tk ? JSON.parse(JSON.stringify(this.top_post.tk_view_value)) : JSON.parse(JSON.stringify(this.top_post.ig_view_value));
                    arr.forEach(item => {
                        if (item.field === 'engagement_rate') {
                            item.value = HandleNumSpec(val[item.field]) !== '---' ? `${HandleNumSpec(val[item.field])}%` : HandleNumSpec(val[item.field]);
                        } else {
                            item.value = HandleNum(val[item.field]);
                        }

                    });
                    return arr;
                }

                this.chart_head1 = pushValue(vals[0].stats[0], 'tk');
                this.chart_head2 = pushValue(vals[1].stats[0]);
                this.chart_head3 = pushValue(vals[1].stats[1]);
                this.tiktok_posts_count = vals[0].stats[0].total;
                this.instagram_posts_count = vals[1].stats[0].total;
                this.story_count = vals[1].stats[1].total;
                this.stat_loading = false;
            }).catch(err => {
                console.log('err', err)
                this.stat_loading = false;
            })
        },

        callApiAgain() {
            this.getPosts();
        },

        updateDate(date) {
            this.start_date = moment(date.start_date).format('YYYY-MM-DD');
            this.end_date = moment(date.end_date).format('YYYY-MM-DD');
            this.getStatistic();
            this.getPosts();
        },

        getPosts(is_next = false) {
            this.top_post.loading = true;
            const sp = this.top_post.top_menu_index === 0 ? 'tikTok' : 'instagram';
            const tag_ids = [];
            this.selected_tags.forEach((tag) => {
                tag_ids.push(tag.id);
            });

            if (is_next) {
                this.top_post.sort.page += 1;
            } else {
                this.top_post.sort.page = 1;
            }

            const params_data = {
                anchor_creator_id: this.user_id,
                merchant_account_id: this.$store.state.user_data.merchant_account_id,
                start_date: this.start_date,
                end_date: this.end_date,
                sort_key: this.top_post.sort.sort_key,
                sort_val: this.top_post.sort.sort_val,
                page: this.top_post.sort.page,
                per_page: this.top_post.sort.per_page,
                with_extra_count: '',
                social_platform: sp,
                tag_ids: tag_ids,
                brand_post_only: 1,
                social_post_type: this.handle_post_type()//this.socisl_post_type,
            }

            this.$ajax(`creator/get-social-account-posts`, {
                params: params_data,
                method: 'get',
            }).then(res => {
                let getV = res.data.data;
                if (is_next) {
                    this.top_post.data.push(...getV.social_posts.data);
                } else {
                    this.top_post.data = getV.social_posts.data;
                }

                this.top_post.loading = false;
                this.is_load_nextpage = false;

                const getCountForPostCategory = (field) => {
                    for (let item of res.data.data.social_post_category) {
                        if (item.social_post_category === field) {
                            return item.count;
                        }
                    }
                    return 0;
                }
                this.top_post.ig_menu[0].value = getCountForPostCategory('post');
                this.top_post.ig_menu[1].value = getCountForPostCategory('reel');
                this.top_post.ig_menu[2].value = getCountForPostCategory('story');
                this.is_end = getV.social_posts.last_page <= getV.social_posts.current_page;
                if (!this.is_end) {
                    this.getPosts(true);
                }
            });
        },

        goSetting() {
            window.location.href = '/settings/content-listening';
        },

        importAndRefrash() {
            this.getPosts();
            this.is_show_import_modal = false;
        }

    },

    mounted() {
        if (this.handle_date_range) {
            this.start_date = this.handle_date_range.start_date;
            this.end_date = this.handle_date_range.end_date;
        }

        this.EventBus.on('updated-all-post-tag', (tags) => {
            if (this.selected_tags) this.selected_tags.forEach((tag, tag_index) => {
                const index = tags.findIndex((o) => o.id === tag.id);
                if (index === -1) {
                    // remove this tag
                    this.selected_tags.splice(tag_index, 1);
                }
                this.getPosts();
            });

            this.tags = tags;
        });
        this.top_post.top_menu_index=0;
        this.getTags();
        this.getStatistic();
        this.getPosts();

    },

    // created() {
    //     this.el = document.querySelector('#modal-body');
    //     console.log('created => ' + this.el)
    //     if (this.el !== null) this.el.addEventListener('scroll', this.handleScroll);
    // },

    // destroyed() {
    //     console.log('destroyed => ' + this.el)
    //     if (this.el !== null) this.el.removeEventListener('scroll', this.handleScroll);
    // }

};
</script>

<style lang="scss">
.brand-posts {
    margin-top: 16px;

    .mx-datepicker-content {
        margin-left: 0px;
    }

    .js-open-tags svg {
        pointer-events: none;
    }

    .tag-menus {
        display: flex;
    }

    .sticker-tab-box-inside {
        margin-left: 0;
        margin-bottom: 16px;
    }

    .sticker-tab-box {
        margin: 0;
        flex: 1;
    }

    .new-btn {
        position: relative;
        border-radius: 6px !important;
        height: 24px;
        width: 24px;
        padding: 0;
        margin-right: 8px;
        max-width: 24px;
        border: none;

        svg {
            fill: white;
            height: 12px;
            width: 12px;
            margin-right: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .import-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 6px !important;
        margin: 0 0 0 10px;
        font: var(--font-med-12);

        svg {
            fill: white;
            height: 10px;
            width: 10px;
            margin-right: 6px;
        }
    }

    .head-info {
        display: block;
        margin: 0 0 16px;

        .link {
            color: var(--blue);
            cursor: pointer;
        }

        .title {
            margin-bottom: 16px;
            display: flex;
            align-items: flex-end;

            &>h2 {
                flex: 1;

                &>span {
                    font: var(--font-demi-24);
                    color: var(--black-90);
                    margin: 0;
                    flex: 1;
                    display: block;
                }

                &>p {
                    font-size: 12px;
                    color: var(--black-75);
                }

            }


        }

        // .bot {
        //     display: flex;
        //     align-items: center;
        // }

        .update-time {
            display: flex;
            align-items: center;
            height: 32px;

            time {
                font-size: 12px;
                color: var(--black-75);
                padding-right: 8px;
            }
        }


        .select-tags {
            display: inline-flex;
            align-items: center;
            margin: 0;
            position: relative;

            &.is-loading {
                pointer-events: none;
                opacity: 0.5;
            }

            &>h3 {
                padding: 0 4px;
                font: var(--font-med-12);
                color: var(--black-90);
            }

            &>.tags {
                display: flex;
                align-items: center;
                padding-right: 12px;

                .ui-checkbox {
                    margin: 0 0 0 16px;
                    font: var(--font-med-12);
                    color: var(--black-90);

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            &>.open-popup {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                border-radius: 6px;
                cursor: pointer;
                margin-left: 4px;

                svg {
                    fill: var(--black-90);
                    height: 14px;
                    width: 14px;
                }

                &:hover {
                    background-color: var(--bg-02);

                    svg {
                        fill: var(--dark-blue);
                    }
                }
            }
        }



        // .userhandle {
        //     font: var(--font-demi-20);
        //     line-height: 32px;
        //     color: var(--black-90);
        //     flex: 1;
        //     margin: 16px 0;
        // }
    }

    .content-box {
        display: block;

        .top-brand-post-box .post-list-box {
            background-color: white;
            padding: 0;
        }

    }

    // .creator-no-data {
    //     max-width: 480px;
    // }

    // .post-list {
    //     background-color: var(--bg-02);
    // }
}
</style>
